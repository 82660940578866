.calculator-view {
  width: 89%;
  max-width: 833px;
  margin: auto;

  .calculator-card {
    position: relative;
    z-index: 2;

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      //backdrop-filter: blur(40px);
    }

    /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    }

    border-radius: 10px;
    padding: 20px;
    width: 100%;

    .calculator-card-grid {
      margin: 0;
      width: 100%;
    }

    .calculator-card-header {
      .calculator-card-header-title {
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 4px;
      }

      .calculator-card-header-subtitle {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
      }
    }

    .calculator-card-metrics {
      display: flex;
      width: 100%;
      padding: 0;
      text-align: center;
      margin-top: 10px;

      .calculator-card-apy,
      .calculator-card-tvl,
      .calculator-card-index {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        .calculator-card-metrics-title {
          font-weight: 500;
          font-size: 20px;
          margin-top: 10px;
          margin-bottom: 2px;
          color: #22b05e;
        }

        .calculator-card-metrics-value {
          font-weight: bold;
          font-size: 24px;
          margin-top: 10px;
          margin-botom: 0px;
          line-height: 29px;
        }
      }
    }

    .calculator-card-area {
      .calculator-card-wallet-notification {
        text-align: center;

        .calculator-card-wallet-connect-btn {
          box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
          border-radius: 10px;
          padding: 14px 58px;
          cursor: pointer;
          margin: auto;
          max-width: 280px;
          margin-top: 20px;

          p {
            font-weight: 600;
            font-size: 18px;
          }
        }

        .calculator-card-wallet-desc-text {
          font-weight: 300;
          font-size: 14px;
          margin-top: 10px;
          margin-bottom: 6px;
        }
      }

      .calculator-card-action-area {
        width: 100%;
        margin-top: 25px;

        .calculator-card-action-area-inp-wrap {
          .calculator-card-action-area-inp-wrap-title {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 5px;
            margin-left: 10px;
            color: #22b05e;
          }

          .calculator-card-action-input {
            width: 100%;

            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 10px;
            outline: none;

            .MuiOutlinedInput-input {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              padding: 14px 20px;
            }

            .MuiOutlinedInput-notchedOutline {
              //border-color: rgba(255, 255, 255, 0.2) !important;
            }

            .stake-card-action-input-btn {
              cursor: pointer;

              p {
                font-weight: 600;
                font-size: 18px;
              }
            }
          }
        }
      }

      .calculator-days-slider-wrap {
        margin-top: 30px;

        .calculator-days-slider-wrap-title {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          margin-bottom: 10px;
        }

        .calculator-days-slider {
          .MuiSlider-rail,
          .MuiSlider-track {
            height: 10px;
          }

          .MuiSlider-rail {
            border-radius: 10px;
            border: 1px solid #64c9fc;
          }

          .MuiSlider-track {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          .MuiSlider-thumb {
            width: 20px;
            height: 20px;
            border: 2px solid #446cf6;
          }
        }
      }

      .calculator-user-data {
        justify-content: center;
        margin: auto;
        margin-top: 10px;
        font-size: 0.875rem;
        font-weight: 500;
      }
      .calculator-user-data .data-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .calculator-user-data .data-row-name,
      .calculator-user-data .data-row-value {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .calculator-view {
    .calculator-card {
      .calculator-card-metrics {
        .calculator-card-apy,
        .calculator-card-tvl,
        .calculator-card-index {
          align-items: flex-start;
        }

        .calculator-card {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .calculator-card-area {
        .calculator-card-action-area {
          .calculator-card-action-row {
            flex-direction: column;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1240px) {
  .calculator-view {
    width: 100%;
  }
}
